import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';

import { Button, Col, Row } from 'react-bootstrap';
import Overlay from 'containers/Overlay';
import classnames from 'classnames';
import ButtonItem from 'components/inputs/ButtonItem';
import { FILETYPES } from 'constants';

const FileTypeFilterOverlay = (props) => {
  const { position, isOpen, onCancel, onDone, filters } = props;
  const [currentFilters, setCurrentFilters] = useState({});

  const fileTypes = FILETYPES.map((item, index) => {
    return { value: item, id: index };
  });

  useEffect(() => {
    if (isOpen) {
      setCurrentFilters(filters);
    }
  }, [filters, isOpen]);

  const onChange = (key) => {
    const newFilters = { ...currentFilters };
    newFilters[key] = !newFilters[key];
    setCurrentFilters(newFilters);
  };

  const selectAll = () => {
    const newFilters = {};
    fileTypes.forEach((item) => {
      newFilters[item.id] = true;
    });

    setCurrentFilters(newFilters);
  };

  const selectNone = () => {
    setCurrentFilters({});
  };

  const Footer = () => {
    return (
      <Row className={styles.footer} noGutters>
        <Col lg="auto">
          <Button
            className="button_reg"
            data-cy-id="FileTypeFilterOverlay-CancelButton"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Col>
        <Col lg="auto">
          <Button
            className="button_call"
            data-cy-id="FileTypeFilterOverlay-DoneButton"
            onClick={() => onDone(currentFilters)}
            disabled={
              Object.keys(currentFilters).filter((key) => currentFilters[key])
                .length < 1
            }
          >
            Done
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <Overlay
      data-cy-id="FileTypeFilterOverlay"
      isOpen={isOpen}
      position={position}
      contentClassName={styles.root}
      footer={<Footer />}
      onCancel={onCancel}
      showClose={false}
      bodyClassName={styles.body}
    >
      <Row className={styles.header} noGutters>
        <span> Select: </span>
        <Button
          data-cy-id="FileTypeFilterOverlay-AllButton"
          className={styles.link}
          onClick={selectAll}
        >
          All
        </Button>
        <span> | </span>
        <Button
          data-cy-id="FileTypeFilterOverlay-NoneButton"
          className={styles.link}
          onClick={selectNone}
        >
          None
        </Button>
      </Row>
      <Row className={styles.listboxContainer}>
        <Col className={styles.listbox}>
          {fileTypes.map((item, index) => {
            const { id, value } = item;
            return (
              <Col
                key={index}
                className={classnames('d-flex align-items-center w-100')}
                md="auto"
              >
                <ButtonItem
                  data-cy-class="FileTypeFilterOverlay-FilterCheckbox"
                  label={value}
                  value={id}
                  type="checkbox"
                  classname={'m-1'}
                  isChecked={currentFilters[id] || false}
                  onChange={() => onChange(id)}
                />
              </Col>
            );
          })}
        </Col>
      </Row>
      <br />
    </Overlay>
  );
};

FileTypeFilterOverlay.propTypes = {
  position: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

export default FileTypeFilterOverlay;
