import config from 'config';
import { APIRequestHandler } from 'classes';
import {
  DEFAULT_API_REQUEST_PAGE_SIZE,
  FLIGHT_GET_API_REQUEST_PAGE_SIZE,
} from 'constants';

// grab needed configuration
// destructuring technique to rename the variable
const { name: API_NAME } = config.amplify.API.endpoints[0];

// define all default header values here
// this is where you put headers that will apply
// to all calls
const defaultHeaders = {};

const APIHandler = new APIRequestHandler(API_NAME, {
  headers: defaultHeaders,
  offline: config.environment.offline,
  mocks: config.environment.offline ? require('libs/api/mocks') : null,
});

const DEFAULT_REPORTING_ACCEPT =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export function a220LogListGET(aids, startDt, endDt, fileTypes) {
  if ((!aids.length || !startDt, !endDt))
    throw new Error('aid, start and end dates are required');
  return APIHandler.request('post', '/logs/list', {
    queryStringParameters: { startDt, endDt },
    body: { aids: aids, fileTypes: fileTypes },
  });
}

export function a220LogsPOST(filenames) {
  if (!filenames) throw new Error('filename is required');
  return APIHandler.request('post', '/logs', {
    body: { filenames },
  });
}

export function a220LogsGET(exportKey) {
  if (!exportKey) throw new Error('export key is required');
  return APIHandler.request('get', '/logs', {
    queryStringParameters: { exportKey },
  });
}

export function adminAircraftDELETE(aid) {
  if (!aid) throw new Error('aid is required');
  return APIHandler.request('delete', '/admin/aircraft', {
    queryStringParameters: { aid },
  });
}

export function adminAircraftPOST(aircraft) {
  return APIHandler.request('post', '/admin/aircraft', { body: aircraft });
}

export function adminConfigurationPATCH(configurationChanges) {
  return APIHandler.request('patch', '/admin/configuration', {
    body: configurationChanges,
  });
}

export function adminMaintenanceModePUT(enabled) {
  return APIHandler.request('put', '/admin/maintenance', {
    queryStringParameters: { enabled },
  });
}

export function adminRevokeCertificateDELETE(aid) {
  if (!aid) throw new Error('aid is required');
  return APIHandler.request('delete', '/admin/aircraft/certificate', {
    queryStringParameters: { aid },
  });
}

export function aircraftGETAll(dt) {
  return APIHandler.request('post', '/aircrafts', {
    queryStringParameters: {
      all: true,
      dt,
      summary: true,
      deleted: dt ? true : false,
    },
  });
}

export function aircraftGETById(aid, dt) {
  if (!aid) throw new Error('aid is required');
  return APIHandler.request('get', '/aircraft', {
    queryStringParameters: { aid, dt, summary: false },
  });
}

export function aircraftGETByTail(tail, dt) {
  if (!tail) throw new Error('tail is required');
  return APIHandler.request('get', '/aircraft', {
    queryStringParameters: { tail, dt, summary: false },
  });
}

export function configurationGET() {
  return APIHandler.request('get', '/configuration');
}

export function consolidatedOffloadGETAll() {
  return APIHandler.request('get', '/offloads', {
    queryStringParameters: { all: true },
  });
}

export function consolidatedOffloadPOST(filename) {
  if (!filename) throw new Error('File Name is required');
  return APIHandler.request('post', '/offloads', {
    body: { bundle_file_name: filename },
  });
}

export function consolidatedOffloadPUT(endpoint, body) {
  return fetch(endpoint, {
    method: 'PUT',
    cache: 'no-cache',
    body,
  });
}

export function flightsGETAll(
  dt,
  page = 1,
  pageSize = FLIGHT_GET_API_REQUEST_PAGE_SIZE,
) {
  return APIHandler.request('post', '/flights', {
    queryStringParameters: {
      all: true,
      dt,
      summary: true,
      pg: page,
      ps: pageSize,
    },
  });
}

export function flightGETById(fid, dt) {
  if (!fid) throw new Error('fid is required');
  return APIHandler.request('get', '/flight', {
    queryStringParameters: { dt, fid, summary: false },
  });
}

export function flightsGETByNumber(num, dt) {
  if (!num) throw new Error('num is required');
  return APIHandler.request('post', '/flights', {
    queryStringParameters: { dt, num, summary: true },
  });
}

export function lrusGETAllByType(
  type,
  {
    modifiedDate = null,
    page = 1,
    pageSize = DEFAULT_API_REQUEST_PAGE_SIZE,
    sortField = null,
    sortDirection = 'ascending',
    aircraftType = null,
    tail = null,
    pos = null,
  } = {},
) {
  if (!type) throw new Error('type is required');
  const queryOpts = {
    all: true,
    summary: true,
    type,
    dt: modifiedDate,
    pg: page,
    ps: pageSize,
    sf: sortField,
    sd: sortDirection,
    atid: aircraftType,
    tail,
    pos,
  };
  return APIHandler.request('post', '/lrus', {
    queryStringParameters: queryOpts,
  });
}

export function lruGETById(lid, dt) {
  if (!lid) throw new Error('lid is required');
  return APIHandler.request('get', '/lru', {
    queryStringParameters: { dt, lid, summary: false },
  });
}

export function reportAvailabilityGET(atid, dt) {
  if (!atid) throw new Error('atid is required');
  if (!dt) throw new Error('dt is required');
  return APIHandler.request('get', '/reports/availability', {
    queryStringParameters: { atid, dt },
  });
}

export function reportConfigurableFeaturesGET(atid) {
  if (!atid) throw new Error('Aircraft Id is required');
  return APIHandler.request('get', '/reports/configurablefeatures', {
    queryStringParameters: { atid },
    headers: {
      Accept: DEFAULT_REPORTING_ACCEPT,
    },
    responseType: 'arraybuffer',
  });
}

export function reportFlightGET(fid) {
  if (!fid) throw new Error('Flight Id is required');
  return APIHandler.request('get', '/reports/flight', {
    queryStringParameters: { fid },
    headers: {
      Accept: DEFAULT_REPORTING_ACCEPT,
    },
    responseType: 'arraybuffer',
  });
}

export function reportLrusAndFaultsGET(atid) {
  if (!atid) throw new Error('Fleet is required');
  return APIHandler.request('get', '/reports/lrusFaults', {
    queryStringParameters: { atid },
    headers: {
      Accept: DEFAULT_REPORTING_ACCEPT,
    },
    responseType: 'arraybuffer',
  });
}

export function reportLruDetailGET(aid, ltid, pos) {
  if (!aid) throw new Error('Aircraft Id is required');
  if (!ltid) throw new Error('LRU Type is required');
  if (!pos) throw new Error('Position is required');
  return APIHandler.request('get', '/reports/lrudetail', {
    queryStringParameters: { aid, ltid, pos },
    headers: {
      Accept: DEFAULT_REPORTING_ACCEPT,
    },
    responseType: 'arraybuffer',
  });
}

export function reportShipGET(aid) {
  if (!aid) throw new Error('Aircraft Id is required');
  return APIHandler.request('get', '/reports/ship', {
    headers: {
      Accept: DEFAULT_REPORTING_ACCEPT,
    },
    responseType: 'arraybuffer',
    queryStringParameters: { aid },
  });
}

export function reportUsageGET(atid, aid, arrDt) {
  if (!arrDt) throw new Error('Arrival date is required');
  return APIHandler.request('get', '/reports/usage', {
    queryStringParameters: { atid, aid, arrDt },
  });
}

export function adminGroupGET(gid) {
  return APIHandler.request('get', '/admin/group', {
    queryStringParameters: { gid },
  });
}

export function adminGroupPUT(updatedGroup) {
  if (!updatedGroup) throw new Error('Group is required');
  return APIHandler.request('put', '/admin/group', { body: updatedGroup });
}

export function adminUserGET(uid) {
  return APIHandler.request('get', '/admin/user', {
    queryStringParameters: { uid },
  });
}

export function adminUserPUT(updatedUser) {
  if (!updatedUser) throw new Error('User is required');
  return APIHandler.request('put', '/admin/user', { body: updatedUser });
}

export function userGET() {
  return APIHandler.request('get', '/user');
}

export function userNotificationsGET(dt) {
  return APIHandler.request('get', '/user/notifications', {
    queryStringParameters: { dt },
  });
}

export function userPOST(user) {
  if (!user) throw new Error('User is required');
  return APIHandler.request('post', '/user', { body: user });
}

export function userPUT(updatedUser) {
  if (!updatedUser) throw new Error('User is required');
  return APIHandler.request('put', '/user', { body: updatedUser });
}
