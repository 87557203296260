import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import {
  AIRCRAFT_TYPES,
  AIRCRAFT_TYPE_A220100,
  AIRCRAFT_TYPE_A220300,
  DEFAULT_OPTION,
  FILETYPES,
  SELECT_OPTION_ALL,
} from 'constants';

import { a220LogListGET, a220LogsPOST, a220LogsGET } from 'libs/api';

import { Row, Col, Button } from 'react-bootstrap';
import DatePicker from 'components/inputs/DatePicker';
import Select from 'components/inputs/Select';
import SkyPulseTable from 'components/SkyPulseTable';
import TailFilterOverlay from './TailFilterOverlay';
import FileTypeFilterOverlay from './FileTypeFilterOverlay';
import LoadingSpinner from 'components/LoadingSpinner';

import styles from './styles.module.css';

const idPrefix = 'A220Logs';
const numberOfTries = 12;

const A220Logs = (props) => {
  const { aircraftList } = props;

  const [isLoading, setIsLoading] = useState(false);

  // log list filters
  const [fleetOption, setFleetOption] = useState(SELECT_OPTION_ALL.value);
  const [aircraftOptions, setAircraftOptions] = useState([]);
  const [selectedAircraftIds, setSelectedAircraftIds] = useState([]);
  const [logfileStartDt, setLogfileStartDt] = useState(null);
  const [logfileEndDt, setLogfileEndDt] = useState(null);
  const [calltoFetchLogs, setCalltoFetchLogs] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [tailFilter, setTailFilter] = useState({});
  const [fileTypeFilter, setFileTypeFilter] = useState({});
  const [tailFilterOverlayCoordinates, setTailFilterOverlayCoordinates] =
    useState(null);
  const [
    fileTypeFilterOverlayCoordinates,
    setFileTypeFilterOverlayCoordinates,
  ] = useState(null);

  // logfile lists
  const [availableLogfiles, setAvailableLogfiles] = useState([]);

  const tailButtonRef = useRef();
  const fileTypeButtonRef = useRef();

  const columnDefinitions = [
    {
      name: 'Filename',
      key: 'filename',
      size: 'large',
      sortable: true,
    },
    {
      name: 'Size',
      key: 'size',
      size: 'large',
      sortable: true,
    },
  ];

  const A220FleetOptions = [SELECT_OPTION_ALL].concat(
    AIRCRAFT_TYPES.getSortedEnumerationItems()
      .filter(
        (item) =>
          item.id === AIRCRAFT_TYPE_A220100.id ||
          item.id === AIRCRAFT_TYPE_A220300.id,
      )
      .map((item) => {
        const { id } = item;
        return { name: AIRCRAFT_TYPES[id].name, value: id };
      }),
  );

  const filteredTailOptions = !aircraftList
    ? []
    : (!fleetOption
        ? aircraftOptions
        : aircraftOptions.filter(
            (aircraft) => aircraft?.aircraft_type?.id === fleetOption,
          )
      )
        .map((aircraft) => {
          return {
            id: aircraft.id,
            value: `${aircraft.tail} (${aircraft.registration || 'NA'})`,
          };
        })
        .sort((a, b) => a.value.localeCompare(b.value));

  const onTailFilterDone = (filters) => {
    setTailFilter(filters);
    const ids = Object.keys(filters)
      .filter((key) => filters[key])
      .map((key) => key);
    setSelectedAircraftIds(ids);
    setTailFilterOverlayCoordinates(null);
  };

  const onFilterPickerOpen = (ref) => {
    const { left, top } = ref.current.getBoundingClientRect();
    return { x: left, y: top };
  };

  const onFileTypeFilterDone = (filters) => {
    setFileTypeFilter(filters);
    setFileTypeFilterOverlayCoordinates(null);
  };

  useEffect(() => {
    const filteredOptions = [DEFAULT_OPTION].concat(
      !aircraftList.length
        ? []
        : aircraftList.filter((aircraft) => {
            return (
              aircraft.aircraft_type.id === AIRCRAFT_TYPE_A220100.id ||
              aircraft.aircraft_type.id === AIRCRAFT_TYPE_A220300.id
            );
          }),
    );
    setAircraftOptions(filteredOptions);
  }, [aircraftList]);

  const handleLogFetch = async () => {
    try {
      setIsLoading(true);
      setAvailableLogfiles([]);
      setCalltoFetchLogs(true);
      const fileTypes = Object.keys(fileTypeFilter)
        .filter((key) => !!fileTypeFilter[key])
        .map((index) => FILETYPES[index]);
      const response = await a220LogListGET(
        selectedAircraftIds,
        logfileStartDt / 1000,
        logfileEndDt / 1000,
        fileTypes,
      ).catch((e) => {
        setIsLoading(false);
      });

      const {
        data: {
          result: { logs: availableLogfiles },
        },
      } = response;

      const rowData = availableLogfiles.map(
        ({ filename: logfile, size }, index) => {
          return {
            id: logfile,
            filename: (
              <div
                key={index}
                className={'skypulsetable_toggle_button'}
                data-clickable-id={logfile}
              >
                {logfile}
              </div>
            ),
            size,
          };
        },
      );

      setAvailableLogfiles(rowData);
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogsGet = (exportKey) => {
    return new Promise((resolve) => {
      let cnt = 0;
      const refreshIntervalId = setInterval(async () => {
        cnt++;
        return await a220LogsGET(exportKey)
          .then((result) => {
            setIsLoading(false);
            clearInterval(refreshIntervalId);
            resolve(result);
          })
          .catch(() => {
            if (cnt > numberOfTries) {
              clearInterval(refreshIntervalId);
              setIsLoading(false);
            }
          });
      }, 5000);
    });
  };

  const handleLogDownload = async (downloadAll, selectedLogFile = null) => {
    try {
      setIsLoading(true);

      const files = downloadAll
        ? availableLogfiles.map((data) => data.id)
        : [selectedLogFile];

      const { data } = await a220LogsPOST(files).catch(() => {
        setIsLoading(false);
      });
      const exportKey = data?.result;
      const response = await handleLogsGet(exportKey);

      const {
        data: { result: secureUrl },
      } = response;

      const anchor = document.createElement('a');
      anchor.setAttribute('href', secureUrl);
      anchor.setAttribute('target', '_blank');
      anchor.click();
    } catch (e) {
      console.warn(e);
    } finally {
      // cleanup
      setIsLoading(false);
    }
  };

  const renderSelectedTails = () => {
    if (selectedAircraftIds.length < 1) {
      return '--';
    }
    const selectedTails = filteredTailOptions.filter((item) =>
      selectedAircraftIds.includes(item.id),
    );
    return selectedTails.map((item) => item.value.split(' ')[0]).join(', ');
  };

  const renderSelectedFileTypes = () => {
    if (fileTypeFilter.length < 1) {
      return '--';
    }
    const selectedTypes = Object.keys(fileTypeFilter).filter(
      (key) => !!fileTypeFilter[key],
    );
    return `${selectedTypes.length} selected`;
  };

  return (
    <>
      <Row className={styles.root} noGutters>
        <Col>
          <Row className={styles.title} noGutters>
            <Col className="title_1">A220 Logs</Col>
            <Col md="auto" className={styles.note}>
              Note: logs can be fetched on a per-day basis and downloaded in
              batches
            </Col>
          </Row>
          <Row
            className={styles.filterRow}
            data-cy-id={`${idPrefix}-Filters`}
            noGutters
          >
            <Col>
              <Row>
                <Col md="auto">
                  <Select
                    idPrefix={`${idPrefix}-Fleet`}
                    data-cy-id={`${idPrefix}-FiltersPanel-FleetSelect`}
                    label="Fleet: "
                    options={A220FleetOptions}
                    value={fleetOption}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setFleetOption(value);
                      setSelectedAircraftIds([]);
                    }}
                    classname={styles.filterControl}
                  />
                </Col>
                <Col md="auto">
                  Tail
                  <Button
                    ref={tailButtonRef}
                    onClick={() => {
                      setTailFilterOverlayCoordinates(
                        onFilterPickerOpen(tailButtonRef),
                      );
                    }}
                    className={classnames(
                      'button_reg',
                      'ml-2',
                      styles.tailButton,
                      styles.filterControl,
                    )}
                  >
                    {renderSelectedTails()}
                  </Button>
                </Col>
                <Col md="auto">
                  File Type
                  <Button
                    ref={fileTypeButtonRef}
                    onClick={() => {
                      setFileTypeFilterOverlayCoordinates(
                        onFilterPickerOpen(fileTypeButtonRef),
                      );
                    }}
                    className={classnames(
                      'button_reg',
                      'ml-2',
                      styles.tailButton,
                      styles.filterControl,
                    )}
                  >
                    {renderSelectedFileTypes()}
                  </Button>
                </Col>
                <Col md="auto">
                  <DatePicker
                    idPrefix={`${idPrefix}-Date`}
                    label="Logfile Start Date: "
                    value={logfileStartDt}
                    onChange={(date) => {
                      const dt = new Date();
                      dt.setTime(
                        new Date(date).getTime() + 24 * 60 * 60 * 1000,
                      );
                      setMinDate(dt);
                      setLogfileStartDt(parseInt(date, 10));
                      setLogfileEndDt(null);
                    }}
                    maxDate={Date.now()}
                  />
                </Col>
                <Col md="auto">
                  <DatePicker
                    idPrefix={`${idPrefix}-Date`}
                    label="Logfile End Date: "
                    value={logfileEndDt}
                    onChange={(date) => {
                      setLogfileEndDt(parseInt(date, 10));
                    }}
                    maxDate={Date.now()}
                    minDate={minDate}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="auto">
              <Button
                className={classnames('button_foc', styles.filterControl)}
                data-cy-id={`${idPrefix}-LoadLogsButton`}
                disabled={
                  isLoading ||
                  !selectedAircraftIds ||
                  !logfileStartDt ||
                  !logfileEndDt
                }
                onClick={handleLogFetch}
              >
                Fetch Logs
              </Button>
            </Col>
          </Row>
          {!isLoading ? null : <LoadingSpinner />}
          {!calltoFetchLogs ? null : (
            <Row className={styles.dataContainer} noGutters>
              <Col className="h-100">
                <SkyPulseTable
                  title={
                    <Button
                      className="button_foc"
                      data-cy-id={`${idPrefix}-DownloadLogsButton`}
                      disabled={isLoading || !availableLogfiles.length}
                      onClick={() => handleLogDownload(true)}
                    >
                      Download All Logs
                    </Button>
                  }
                  titleClassName={styles.titleContainer}
                  columns={columnDefinitions}
                  rows={availableLogfiles}
                  isLoading={isLoading}
                  showHeaders={true}
                  onRowClick={(id, logFileId = null) => {
                    if (id === 'root' && !logFileId) {
                      return;
                    }
                    handleLogDownload(false, logFileId);
                  }}
                  className={styles.logTable}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <TailFilterOverlay
        position={tailFilterOverlayCoordinates}
        isOpen={tailFilterOverlayCoordinates !== null}
        onCancel={() => setTailFilterOverlayCoordinates(null)}
        onDone={onTailFilterDone}
        filters={tailFilter}
        tails={filteredTailOptions}
      />
      <FileTypeFilterOverlay
        position={fileTypeFilterOverlayCoordinates}
        isOpen={fileTypeFilterOverlayCoordinates !== null}
        onCancel={() => setFileTypeFilterOverlayCoordinates(null)}
        onDone={onFileTypeFilterDone}
        filters={fileTypeFilter}
      />
    </>
  );
};

export default A220Logs;
